import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export default class GlobalService {
  public api_url = environment.url_base_api;

  getHeaders(contentType: string = 'application/json') {
    const options = {
      'Accept-Language': environment.default_language,
    };

    if (!!localStorage.getItem('token')) {
      options['Authorization'] = `Token ${localStorage.getItem('token')}`;
    }

    if (contentType) {
      options['Content-Type'] = contentType;
    }

    return new HttpHeaders(options);
  }
}
