<mat-action-list>
  <button mat-list-item (click)="validate()">
    <mat-icon color="accent">done</mat-icon>
    <ng-container>
      {{ data.confirmMessage }}
    </ng-container>
  </button>
  <button mat-list-item (click)="cancel()">
    <mat-icon color="warn">close</mat-icon>
    <ng-container>Cancel</ng-container>
  </button>
</mat-action-list>
