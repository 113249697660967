import { Injectable } from '@angular/core';
import GlobalService from '../globalService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseApi, SearchField } from '../../models/api';
import { Observable } from 'rxjs';
import { IExploitationStatus, ExploitationStatus } from '../../models/exploitation-status.model';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExploitationStatusService extends GlobalService {
  url_exploitation_status = `${this.api_url}/exploitation_statuses/`;

  constructor(public http: HttpClient) {
    super();
  }

  list(searchFields: SearchField): Observable<ResponseApi<ExploitationStatus>> {
    const headers = this.getHeaders();

    const params: HttpParams = new HttpParams({
      fromObject: searchFields,
    });

    return this.http.get<ResponseApi<IExploitationStatus>>(this.url_exploitation_status, { headers, params }).pipe(
      map((responseApi: ResponseApi<IExploitationStatus>) => {
        return {
          ...responseApi,
          results: responseApi.results.map((data) => new ExploitationStatus(data)),
        };
      })
    );
  }

  create(exploitation_status): Promise<IExploitationStatus> {
    const headers = this.getHeaders(null);
    return this.http
      .post<any>(this.url_exploitation_status, exploitation_status, { headers: headers })
      .pipe(first())
      .toPromise();
  }

  update(url: string, exploitation_status: any): Promise<any> {
    const headers = this.getHeaders(null);
    return this.http.patch<any>(url, exploitation_status, { headers: headers }).pipe(first()).toPromise();
  }

  remove(url: string): Promise<any> {
    const headers = this.getHeaders();
    return this.http.delete<any>(url, { headers: headers }).pipe(first()).toPromise();
  }

  get(id): Observable<ExploitationStatus> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.url_exploitation_status + id, { headers: headers }).pipe(
      map((exploitation_status_data) => {
        return new ExploitationStatus(exploitation_status_data);
      })
    );
  }
}
