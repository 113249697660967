import { Injectable } from '@angular/core';
import GlobalService from '../globalService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseApi, SearchField } from '../../models/api';
import { Observable } from 'rxjs';
import { IInstallation, Installation } from '../../models/installation.model';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InstallationService extends GlobalService {
  url_installation = `${this.api_url}/installations/`;

  constructor(public http: HttpClient) {
    super();
  }

  list(searchFields: SearchField): Observable<ResponseApi<Installation>> {
    const headers = this.getHeaders();

    const params: HttpParams = new HttpParams({
      fromObject: searchFields,
    });

    return this.http.get<ResponseApi<IInstallation>>(this.url_installation, { headers, params }).pipe(
      map((responseApi: ResponseApi<IInstallation>) => {
        return {
          ...responseApi,
          results: responseApi.results.map((data) => new Installation(data)),
        };
      })
    );
  }

  create(installation): Promise<IInstallation> {
    const headers = this.getHeaders();
    return this.http.post<any>(this.url_installation, installation, { headers: headers }).pipe(first()).toPromise();
  }

  update(url: string, installation: Installation): Promise<any> {
    const headers = this.getHeaders();
    return this.http.patch<any>(url, installation, { headers: headers }).pipe(first()).toPromise();
  }

  get(id): Observable<Installation> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.url_installation + id, { headers: headers }).pipe(
      map((installation_data) => {
        return new Installation(installation_data);
      })
    );
  }
}
