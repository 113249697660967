import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetConfirmationComponent } from '../components/bottom-sheet-confirmation/bottom-sheet-confirmation.component';

@Directive({
  selector: '[appBottomSheetConfirm]',
})
export class BottomSheetConfirmDirectiveDirective {
  defaultConfirmMessage = 'Confirmer la suppression';

  @Input()
  appBottomSheetConfirm: string;

  @Output()
  confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('click', ['$event']) onClick(eventClick) {
    eventClick.stopPropagation();
    const bottomSheetRef = this._bottomSheet.open(BottomSheetConfirmationComponent, {
      data: {
        confirmMessage: this.appBottomSheetConfirm || this.defaultConfirmMessage,
      },
    });
    bottomSheetRef.afterDismissed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.confirm.emit(true);
      }
    });
  }

  constructor(private _bottomSheet: MatBottomSheet) {}
}
