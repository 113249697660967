<div class="note-dialog" [formGroup]="note_form" *ngIf="note_form">
  <h2 class="note-dialog__title">{{ dialogTitle }}</h2>
  <div class="note-dialog__form-field">
    <mat-form-field class="note-dialog__form-field__form" appearance="standard">
      <mat-label>Message</mat-label>
      <textarea matInput [formControlName]="'content'" rows="12"></textarea>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="note-dialog__actions">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="note_form.invalid">Submit</button>
  </div>
</div>
