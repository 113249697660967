<div class="installation-dialog" [formGroup]="installation_form" *ngIf="installation_form">
  <h2 class="installation-dialog__title">{{ dialogTitle }}</h2>

  <div class="installation-dialog__form-field installation-dialog__form-field--multi">
    <mat-form-field class="installation-dialog__form-field__form">
      <mat-label>Start date</mat-label>
      <input matInput [ngxMatDatetimePicker]="startPicker" [formControlName]="'start_date'" />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #startPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field class="installation-dialog__form-field__form">
      <mat-label>End date</mat-label>
      <input matInput [ngxMatDatetimePicker]="endPicker" [formControlName]="'end_date'" />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #endPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="installation-dialog__form-field">
    <app-auto-complete
      [formControlName]="'location'"
      [label]="'Location'"
      [choices]="location_options"
      (onCustomSearchChange)="refreshLocationOptions($event)"
    >
    </app-auto-complete>
  </div>

  <div class="installation-dialog__form-field">
    <mat-form-field class="installation-dialog__form-field__form" appearance="standard">
      <mat-label>Description</mat-label>
      <textarea matInput [formControlName]="'description'" rows="8"></textarea>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="installation-dialog__actions">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="installation_form.invalid">Submit</button>
  </div>

  <ng-container *ngIf="non_field_errors.length">
    <div class="installation-dialog__non_field_errors" *ngFor="let error of non_field_errors">
      <span>{{ error }}</span>
    </div>
  </ng-container>
</div>
