import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';
// import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { LayoutService } from './shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from './shared/services/internationalization.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class appComponent implements OnInit, AfterViewInit {
  appTitle = 'UpTaste';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    // private themeService: ThemeService,
    private layout: LayoutService,
    private translate: TranslateService,
    // private authenticationService: AuthenticationService,
    private internationalizationService: InternationalizationService
  ) {
    // *** The default way of checking if user has a preferred language field.
    // if (AuthenticationService.isAuthenticated()) {
    //   this.internationalizationService.setLocale(this.profileService.getProfile().preferred_language);
    // } else {
    //   // this language will be used as a fallback when a translation isn't found in the current language
    //   translate.setDefaultLang(environment.default_language);
    //   this.internationalizationService.setLocale('fr');
    // }
    // translate.setDefaultLang(environment.default_language);
    // this.internationalizationService.setLocale('fr');
  }

  ngOnInit() {
    this.changePageTitle();
    // this.layout.setAppLayout()
  }
  ngAfterViewInit() {}
  changePageTitle() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
