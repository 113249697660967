import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { debounceTime } from 'rxjs/operators';
import { merge } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

export const defaultErrors = {
  required: () => `Field is required`,
};

interface FileUploaded {
  name: string;
  size: number;
  file_url?: string;
}

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent implements OnInit, ControlValueAccessor {
  @Output() newFile: EventEmitter<any> = new EventEmitter<any>();

  @Input() fieldIsRequired = false;
  @Input() fileType: 'image' | 'document' | 'video';
  @Input() overwriteAcceptedType: string[] = [];
  @Input() hideFileViewer = false;
  @Input() maxFileLimit = 1;
  @Input() style: 'column' | 'row' = 'column';
  @Input() reorderList = false;

  refreshing_current_files = false;

  private _value: any = [];
  @Input()
  set value(str: any) {
    this._value = str;
    this.onChange(this._value);
  }

  get value() {
    return this._value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  disabled = false;
  focus = false;
  error: string;
  uuid: string;
  file_loading = false;

  private _files_uploaded: FileUploaded[] = [];
  private _current_files: { file: File; url: string }[] = [];
  total_file_length = 0;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private formDirective: FormGroupDirective,
    private http: HttpClient
  ) {
    this.uuid = uuid();

    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.control) {
      const observables = [this.control.statusChanges.pipe(debounceTime(1000))];
      if (!!this.formDirective) {
        observables.push(this.formDirective.ngSubmit);
      }
      merge(...observables).subscribe(() => {
        this.checkError();
      });

      this.refreshUploadedFiles();
    }
  }

  async refreshUploadedFiles() {
    if (!this.currentValueUrls) {
      return;
    }

    try {
      this.total_file_length = this.currentValueUrls.length;
      this.refreshing_current_files = true;

      const current_files = [];
      const files_uploaded = [];
      for (const url of this.currentValueUrls) {
        const response = await fetch(url);
        const blob = await response.blob();
        const file_name = url.split('/').pop().split('?X-Goog-Algorithm')[0];
        const file_data = new File([blob], file_name, { type: blob.type });
        const formatted_bytes = this.formatBytes(file_data.size);

        current_files.push({
          file: file_data,
          url: url,
        });
        files_uploaded.push({
          name: `${file_data.name} - ${formatted_bytes}`,
          size: file_data.size,
          file_url: url,
        });
      }

      if (current_files.length) {
        this._current_files.push(...current_files);
      }

      if (files_uploaded.length) {
        this._files_uploaded.push(...files_uploaded);
      }

      this.refreshing_current_files = false;
    } catch (e) {
      console.error(e);
      this.refreshing_current_files = false;
      this.error = 'Something went wrong, please try again';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(str: string[]): void {
    this.value = str;
  }

  checkError() {
    const controlErrors = this.control.errors;
    if (controlErrors && this.control.invalid) {
      const firstKey = Object.keys(controlErrors)[0];
      const getError = defaultErrors[firstKey];
      this.error = getError(controlErrors[firstKey]);
    } else {
      this.error = null;
    }
  }

  get control(): FormControl {
    return !!this.ngControl ? (this.ngControl.control as FormControl) : null;
  }

  get currentValueUrls(): string[] {
    if (!this.value) {
      return [];
    }

    if (typeof this.value === 'string') {
      return [this.value];
    }

    return this.value;
  }

  get fileTypes(): string[] {
    if (this.overwriteAcceptedType.length) {
      return this.overwriteAcceptedType;
    }

    switch (this.fileType) {
      case 'image': {
        return ['image/png', 'image/jpg', 'image/jpeg', 'image/jpe', 'image', 'image/svg+xml'];
      }

      case 'video': {
        return ['video/mp4'];
      }

      case 'document': {
        return [];
      }

      default: {
        return [];
      }
    }
  }

  get isMultipleFiles() {
    return this.maxFileLimit > 1 || this.maxFileLimit === null;
  }

  get uploadedFiles() {
    if (!this._files_uploaded?.length) {
      return null;
    }

    return this._files_uploaded;
  }

  get isUploadDisabled() {
    return this.total_file_length === this.maxFileLimit;
  }

  convertFileListToArrayFile(file_list: FileList) {
    const files: File[] = [];

    if (!file_list?.length) {
      throw Error('No file selected');
    }

    for (let i = 0; i < file_list.length; i++) {
      const file = file_list.item(i);
      files.push(file);
    }

    return files;
  }

  onFileDropped(file_list: FileList): void {
    const converted_files_list = this.convertFileListToArrayFile(file_list);
    this.validateAndUploadFiles(converted_files_list);
  }

  fileBrowseHandler(event: Event) {
    const input_event = event.target as HTMLInputElement;
    const file_list = input_event?.files;

    const converted_files_list = this.convertFileListToArrayFile(file_list);
    this.validateAndUploadFiles(converted_files_list);
  }

  validateAndUploadFiles(files: File[]) {
    try {
      const valid_length = this.checkTotalFileLength(files);

      if (!valid_length) {
        return;
      }

      for (const file of files) {
        const valid_file_type = this.checkFileType(file?.type);

        if (!valid_file_type) {
          return;
        }
      }

      this.emitFiles(files);
    } catch (e) {
      console.error(e);
      this.file_loading = false;

      if (e?.message) {
        this.error = e?.message;
        return;
      }

      this.error = 'Something went wrong, please try again';
    }
  }

  async emitFiles(files: File[]) {
    this.file_loading = true;

    for (const file of files) {
      const url = await this.generateLocalUrl(file);

      const formatted_bytes = this.formatBytes(file.size);
      this.total_file_length++;

      this._current_files.push({
        file,
        url,
      });
      this._files_uploaded.push({
        name: `${file.name} - ${formatted_bytes}`,
        size: file.size,
        file_url: url,
      });

      if (this.maxFileLimit === 1) {
        this.value = file;
        this.newFile.emit(file);
        this.file_loading = false;
        return;
      }
    }
    this.value = [...(this.value?.length ? this.value : []), ...files];
    this.newFile.emit(this.value);
    this.file_loading = false;
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 bytes';
    }
    const kib = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(kib));
    return parseFloat((bytes / Math.pow(kib, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  generateLocalUrl(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.readAsDataURL(file);
    });
  }

  deleteFile(index = null) {
    if (!this.isMultipleFiles) {
      this.total_file_length = 0;
      this._files_uploaded = [];
      this.value = null;
      this.newFile.emit(this.value);
      return;
    }

    if (index === null) {
      return;
    }
    this.total_file_length--;
    const pathIndex = this._current_files.findIndex((path) => path.url === this.uploadedFiles[index].file_url);
    this._files_uploaded.splice(index, 1);
    this._current_files.splice(pathIndex, 1);

    if (!this._current_files?.length) {
      this.value = null;
      this.newFile.emit(this.value);
      return;
    }

    this._current_files.map((data) => {
      if (data.url.startsWith('data')) {
        return data.file;
      }

      return data.url;
    });

    this.value = this._current_files.map((data) => {
      if (data.url.startsWith('data')) {
        return data.file;
      }
      return data.url;
    });

    this.newFile.emit(this.value);
  }

  // Error Handlers
  checkFileType(type: string) {
    if (!type) {
      throw Error('Invalid file type');
    }

    if (this.fileType === 'document') {
      return true;
    }

    if (!this?.fileTypes?.includes(type)) {
      throw Error('Invalid file type');
    }

    return true;
  }

  checkTotalFileLength(files: File[]) {
    if (!files?.length) {
      throw Error('No file selected');
    }

    if (!this.isMultipleFiles) {
      if (files.length > 1) {
        throw Error('Too many files');
      }

      return true;
    }

    if (this.maxFileLimit === null) {
      return true;
    }

    if (files.length + this.total_file_length > this.maxFileLimit) {
      throw Error('Too many files');
    }

    return true;
  }

  reordering(event: CdkDragDrop<any[]>) {
    if (this.reorderList) {
      moveItemInArray(this.uploadedFiles, event.previousIndex, event.currentIndex);
      moveItemInArray(this.value, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity({ emitEvent: true });
    }
  }

  async downloadFile(file: FileUploaded) {
    const http_download = await this.http.get(file.file_url, { responseType: 'blob' }).toPromise();
    const download_link = document.createElement('a');
    download_link.href = window.URL.createObjectURL(new Blob([http_download], { type: http_download.type }));
    download_link.download = file.name.split(' - ')[0];
    download_link.target = '_blank';
    download_link.click();
  }
}
