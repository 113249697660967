import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export interface IBottomSheetConfirmData {
  confirmMessage: string;
}

@Component({
  selector: 'app-bottom-sheet-delete-confirmation',
  templateUrl: './bottom-sheet-confirmation.component.html',
  styleUrls: ['./bottom-sheet-confirmation.component.scss'],
})
export class BottomSheetConfirmationComponent implements OnInit {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetConfirmationComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IBottomSheetConfirmData
  ) {}

  ngOnInit(): void {}

  validate() {
    this._bottomSheetRef.dismiss(true);
  }

  cancel() {
    this._bottomSheetRef.dismiss(false);
  }
}
