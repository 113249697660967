import { Injectable } from '@angular/core';
import GlobalService from '../globalService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseApi, SearchField } from '../../models/api';
import { Observable } from 'rxjs';
import { Location, ILocation } from '../../models/location.model';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends GlobalService {
  url_location = `${this.api_url}/locations/`;

  constructor(public http: HttpClient) {
    super();
  }

  list(searchFields: SearchField): Observable<ResponseApi<Location>> {
    const headers = this.getHeaders();

    const params: HttpParams = new HttpParams({
      fromObject: searchFields,
    });

    return this.http.get<ResponseApi<ILocation>>(this.url_location, { headers, params }).pipe(
      map((responseApi: ResponseApi<ILocation>) => {
        return {
          ...responseApi,
          results: responseApi.results.map((data) => new Location(data)),
        };
      })
    );
  }

  create(location): Promise<ILocation> {
    const headers = this.getHeaders();
    return this.http.post<any>(this.url_location, location, { headers: headers }).pipe(first()).toPromise();
  }

  update(url: string, location: Location): Promise<any> {
    const headers = this.getHeaders();
    return this.http.patch<any>(url, location, { headers: headers }).pipe(first()).toPromise();
  }

  get(id): Observable<Location> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.url_location + id, { headers: headers }).pipe(
      map((location_data) => {
        return new Location(location_data);
      })
    );
  }
}
