import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import GlobalService from '../globalService';
import { User } from '../../models/user.model';

const USER_PROFILE_KEY = 'user_profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends GlobalService {
  url_profile = `${this.api_url}/rest-auth/user/`;

  private _currentProfile: BehaviorSubject<User> = new BehaviorSubject<User>(this.getProfileStorage());

  public currentProfile$: Observable<User> = this._currentProfile.asObservable();

  constructor(public http: HttpClient) {
    super();

    this.currentProfile$.subscribe((profile: User) => {
      if (profile) {
        ProfileService.setProfileStorage(profile);
      } else {
        ProfileService.removeProfileStorage();
      }
    });
  }

  getProfileStorage(): User {
    const local_profile = localStorage.getItem(USER_PROFILE_KEY);

    if (local_profile) {
      return new User().deserialize(JSON.parse(local_profile));
    } else {
      return new User();
    }
  }

  static setProfileStorage(profile: User) {
    localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(profile));
  }

  static removeProfileStorage() {
    localStorage.removeItem(USER_PROFILE_KEY);
  }

  setProfile(profile: User) {
    this._currentProfile.next(profile);
  }

  getProfile(): User {
    return this._currentProfile.getValue();
  }

  removeProfile() {
    this._currentProfile.next(null);
  }

  refreshProfile(): Promise<User> {
    const headers = this.getHeaders();
    return this.http
      .get<any>(this.url_profile, { headers })
      .pipe(
        take(1),
        map((data) => new User().deserialize(data)),
        tap((user: User) => this.setProfile(user))
      )
      .toPromise();
  }
}
