import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class CampaignFormGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const is_session = route.queryParams?.session === 'true';

    if (!is_session) {
      return true;
    }

    if (sessionStorage.getItem('campaign-session')) {
      return true;
    }

    this.router.navigate(['/campaign/search-uptaster']);
  }
}
