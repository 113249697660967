import { Deserializable } from './deserializable';
import { Model } from './generic-model';
import { Country } from './country.model';
export interface IRegion {
  id?: string;
  url?: string;
  created_at?: string;
  updated_at?: string;
  name: string;
  country: Country;
}

export class Region extends Model implements Deserializable {
  created_at?: string;
  updated_at?: string;
  name: string;
  country: Country;

  deserialize(input: IRegion) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data?.country === 'object' && data?.country) {
      data.contact = new Country(data.contact);
    }

    super(data);
  }
}
