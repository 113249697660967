<div class="auto-complete">
  <div class="auto-complete__control">
    <mat-form-field
      class="select-wrapper"
      [ngClass]="{ disabled: control?.disabled }"
      [class.mat-form-field-invalid]="(!!control?.errors && selectionFilterCtrl.touched) || control?.touched"
    >
      <mat-label>
        {{ label }}
      </mat-label>
      <mat-select
        [multiple]="multiSelect"
        [ngClass]="{ 'disabled-select': control?.disabled }"
        id="input-{{ uuid }}"
        [(ngModel)]="value"
        [placeholder]="label"
        #selectionSelect
        (change)="onValueSelected()"
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="selectionFilterCtrl"
            [placeholderLabel]="label"
            [noEntriesFoundLabel]="'No option found'"
            [searching]="true"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let selectChoice of selectionFilter | async; trackBy: trackByFunction"
          [value]="selectChoice.value"
          >{{ selectChoice.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-icon
      class="auto-complete__action"
      *ngIf="addNewForm"
      [matTooltip]="'Add new option'"
      [matTooltipPosition]="'above'"
      (click)="addNewOptionForm()"
      >add_circle_outline
    </mat-icon>

    <div
      class="auto-complete__control__error"
      *ngIf="(!!control?.errors && selectionFilterCtrl.touched) || control?.touched"
    >
      <mat-error *ngIf="control?.errors?.api?.length">{{ control?.errors?.api[0] }}</mat-error>
      <mat-error *ngIf="control?.errors?.required">This field is required.</mat-error>
    </div>
  </div>
</div>
