<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    fxHide.gt-md
    fxHide.md
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>

  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <img src="../../../../assets/images/face-7.jpg" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item (click)="auth.logOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</mat-toolbar>
