<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <div class="branding px-20">
        <img src="assets/images/logo.png" alt="" class="app-logo" />
        <span class="app-logo-text">{{ appName }}</span>
      </div>

      <!-- Sidebar user -->
      <div class="app-user">
        <div class="app-user-photo">
          <img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="" />
        </div>
        <div class="ml-16">
          <span class="app-user-name mb-4">
            {{ username }}
          </span>
          <!-- Small buttons -->
          <div class="app-user-controls">
            <button class="text-muted" mat-icon-button mat-xs-button matTooltip="Log out" (click)="auth.logOut()">
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
    </div>
    <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
    <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
  </div>
</div>
