import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { SelectChoice } from '../../models/generic-model';
import { debounceTime } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  @ViewChild('selectionSelect', { static: true }) selectionSelect: MatSelect;
  selectionFilterCtrl: FormControl = new FormControl();
  selectionFilter: ReplaySubject<SelectChoice[]> = new ReplaySubject<SelectChoice[]>(1);

  @Output() onValueChange = new EventEmitter();
  @Output() onCustomSearchChange = new EventEmitter();

  _choices: SelectChoice[] = [];

  @Input() multiSelect = false;

  @Input() addNewForm: any = null;
  @Input() addNewFormLabel: string = null;
  @Input() addNewFormValue: string = null;

  @Input()
  set choices(choice_options: SelectChoice[]) {
    this._choices = choice_options;
    this.selectionFilter.next(this.choices.slice());
  }

  get choices() {
    return this._choices;
  }
  @Input()
  label: string;

  _value: any;

  @Input()
  set value(str: any) {
    this._value = str;
    this.onChange(this._value);
    this.onValueChange.emit(this.value);
  }

  get value() {
    return this._value;
  }

  disabled = false;
  uuid: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  get control(): FormControl {
    return !!this.ngControl ? (this.ngControl.control as FormControl) : null;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private formDirective: FormGroupDirective,
    private dialog: MatDialog
  ) {
    this.uuid = uuid();
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }

    this.selectionFilterCtrl.valueChanges.pipe(debounceTime(500)).subscribe((textValue: string) => {
      if (textValue !== '') {
        this.onCustomSearchChange.emit(textValue);
      }
    });
  }

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(str: any): void {
    this.value = str;
  }

  trackByFunction(index, item) {
    return item.value;
  }

  onValueSelected() {
    this.onValueChange.emit(this.value);
  }

  async addNewOptionForm() {
    if (!this.addNewForm) {
      return;
    }

    const create_form = this.dialog.open(this.addNewForm);
    create_form.afterClosed().subscribe((create_data) => {
      if (!create_data) {
        return;
      }

      this._choices.push({
        label: create_data[this.addNewFormLabel],
        value: this.addNewFormValue ? create_data[this.addNewFormValue] : JSON.stringify(create_data),
      });

      this.selectionFilter.next(this.choices.slice());
      this._value = this.addNewFormValue ? create_data[this.addNewFormValue] : JSON.stringify(create_data);
      this.onChange(this.value);

      if (!this.control?.parent && !this.addNewFormValue) {
        this.onValueSelected();
      }
    });
  }
}
