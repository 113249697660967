import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Issue } from '../../models/issue.model';
import { ProfileService } from '../../services/auth/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Installation } from '../../models/installation.model';
import { ExploitationStatusService } from '../../services/data/exploitation-status.service';
import { ExploitationStatus } from '../../models/exploitation-status.model';
import { manageFormError } from '../../helpers/FormErrors';

@Component({
  selector: 'app-exploitation-status-dialog',
  templateUrl: './exploitation-status-dialog.component.html',
  styleUrls: ['./exploitation-status-dialog.component.scss'],
})
export class ExploitationStatusDialogComponent implements OnInit {
  exploitation_status_form: FormGroup;

  non_field_errors: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { installation_data: Installation; exploitation_status_data: ExploitationStatus },
    public dialogRef: MatDialogRef<ExploitationStatusDialogComponent>,
    private fb: FormBuilder,
    private exploitationStatusService: ExploitationStatusService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.initExploitationStatusForm();
  }

  get dialogTitle() {
    if (this.data?.exploitation_status_data) {
      return 'Modify Exploitation status';
    }

    return 'Create Exploitation status';
  }

  initExploitationStatusForm() {
    this.exploitation_status_form = this.fb.group({
      start_date: [null, Validators.required],
      end_date: [null],
      content: [null],
    });

    if (this.data?.exploitation_status_data) {
      this.exploitation_status_form.patchValue(this.data.exploitation_status_data);
    }
  }

  async submit() {
    try {
      const exploitation_status_data = {
        installation: this.data.installation_data.url,
        author: this.profileService.getProfile().url,
        start_date: this.exploitation_status_form.value.start_date,
        end_date: this.exploitation_status_form.value.end_date,
        content: this.exploitation_status_form.value.content,
        is_exploited: true,
      };

      if (this.data.exploitation_status_data) {
        await this.exploitationStatusService.update(
          this.data.exploitation_status_data.url,
          new Issue(exploitation_status_data)
        );
        this.dialogRef.close(true);
        this.snackbar.open('Exploitation status modified', 'OK', {
          duration: 2000,
        });
        return;
      }

      await this.exploitationStatusService.create(exploitation_status_data);
      this.dialogRef.close(true);
      this.snackbar.open('Exploitation status created', 'OK', {
        duration: 2000,
      });
    } catch (e) {
      if (e?.error?.non_field_errors?.length) {
        this.non_field_errors = e?.error?.non_field_errors;
      }

      manageFormError(this.exploitation_status_form, e);
      console.error(e);
    }
  }
}
