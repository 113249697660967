import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import GlobalService from '../globalService';
import { environment } from '../../../../environments/environment';
import { take, tap } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { Router } from '@angular/router';

interface AuthenticationResponse {
  token: string;
}

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends GlobalService {
  url_authentication = environment.url_base_api + '/rest-auth/login/';
  url_logout = `${this.api_url}/rest-auth/logout/`;

  constructor(public http: HttpClient, private profileService: ProfileService, private router: Router) {
    super();
  }

  logIn(email: string, password: string): Promise<AuthenticationResponse> {
    const headers = this.getHeaders();
    return this.http
      .post<AuthenticationResponse>(
        this.url_authentication,
        {
          email,
          password,
        },
        { headers }
      )
      .pipe(
        take(1),
        tap((response: any) => {
          this.setToken(response.key);
          this.setToken(response.key);
        })
      )
      .toPromise();
  }

  logOut(): Promise<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`${this.url_logout}`, { headers })
      .pipe(
        take(1),
        tap(() => {
          this.clean();
        })
      )
      .toPromise();
  }

  static get isAuthenticated(): boolean {
    return !!this.token;
  }

  static get token() {
    return localStorage.getItem(TOKEN_KEY);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  clean() {
    this.removeToken();
    this.profileService.removeProfile();
    this.router.navigate(['/sessions/signin']);
  }
}
