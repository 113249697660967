import { Deserializable } from './deserializable';

export interface IUser {
  url?: string;
  id?: number;
  permissions?: [];
  last_login?: string;
  is_superuser?: boolean;
  first_name?: string;
  last_name?: string;
  is_staff?: boolean;
  is_active?: boolean;
  date_joined?: string;
  email?: string;
  groups?: string[];
  user_permissions?: string[];
}

export class User implements Deserializable {
  url?: string;
  id?: number;
  permissions?: [];
  last_login?: string;
  is_superuser?: boolean;
  first_name?: string;
  last_name?: string;
  is_staff?: boolean;
  is_active?: boolean;
  date_joined?: string;
  email?: string;
  groups?: string[];
  user_permissions?: string[];

  deserialize(input: IUser) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        this[name] = data[name];
      }
    }
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }
}
