import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/analytics',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then((m) => m.SessionsModule),
        data: { title: 'Session' },
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'campaign',
        loadChildren: () => import('./views/campaign/campaign.module').then((m) => m.CampaignModule),
        data: { title: 'Campaigns', breadcrumb: 'Campaigns' },
      },
      // {
      //   path: 'users',
      //   loadChildren: () => import('./views/user/user.module').then((m) => m.UserModule),
      //   data: { title: 'Users', breadcrumb: 'Users' },
      // },
      // {
      //   path: 'hardware-version',
      //   loadChildren: () => import('./views/hardware-version/hardware-version.module').then((m) => m.HardwareVersionModule),
      //   data: { title: 'Hardware versions', breadcrumb: 'Hardware versions' },
      // },
      {
        path: 'packaging',
        loadChildren: () => import('./views/packaging/packaging.module').then((m) => m.PackagingModule),
        data: { title: 'Packaging', breadcrumb: 'Packaging' },
      },
      {
        path: 'location',
        loadChildren: () => import('./views/location/location.module').then((m) => m.LocationModule),
        data: { title: 'Locations', breadcrumb: 'Locations' },
      },
      {
        path: 'product',
        loadChildren: () => import('./views/product/product.module').then((m) => m.ProductModule),
        data: { title: 'Products', breadcrumb: 'Products' },
      },
      {
        path: 'market-analysis',
        loadChildren: () =>
          import('./views/market-analysis/market-analysis.module').then((m) => m.MarketAnalysisModule),
        data: { title: 'Market Analysis', breadcrumb: 'Market Analysis' },
      },
      {
        path: 'machines',
        loadChildren: () => import('./views/machine/machine.module').then((m) => m.MachineModule),
        data: { title: 'Machines', breadcrumb: 'Machines' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];
