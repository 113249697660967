import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CustomDataSource } from '../../helpers/CustomDataSource';
import { SearchField } from '../../models/api';
import { ColumnDefinitions } from '../../models/column-definition';

export interface IGenericSubject {
  search?: string;
}

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent<T = any, searchFilter = any> implements OnInit, AfterViewInit {
  @Input() searchEvent: Observable<searchFilter>;
  private searchFilters: searchFilter;

  @Output() pageLoaded = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorBottom', { static: true }) paginatorBottom: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  dataSource: CustomDataSource<T>;

  apiService: any;
  searchFunction: any;
  providedSource: T[] = null;

  displayedColumns: string[];

  columnSelector;

  columnDefinitions: ColumnDefinitions;

  selected_item: T;
  @Output('selected') item_selected: EventEmitter<T> = new EventEmitter<T>();
  @Input('updateSelection') set new_item_selected(value: T) {
    this.selected_item = value;
  }

  get columns(): string[] {
    return Object.keys(this.columnDefinitions);
  }

  get tableMinSize(): number {
    let tableSize = 0;

    // @ts-ignore
    for (const [column_key, column_data] of Object.entries(this.columnDefinitions)) {
      if (this.displayedColumns.includes(column_key)) {
        tableSize += column_data.width;
      }
    }

    return tableSize;
  }

  title(column_key: string): string {
    return this.columnDefinitions[column_key]?.title;
  }

  column_width(column_key: string): string {
    const width: number = this.columnDefinitions[column_key]?.width || 200;
    return width + 'px';
  }

  column_disable(column_key: string): boolean {
    return this.columnDefinitions[column_key]?.disabled;
  }

  constructor() {}

  ngOnInit(): void {
    this.initTable();
    this.columnSelector = new FormControl(this.displayedColumns);
    this.columnSelector.valueChanges.subscribe((columns: string[]) => {
      this.displayedColumns = columns;
    });

    if (this.searchEvent) {
      this.searchEvent.subscribe((event) => {
        if (event) {
          this.searchFilters = event;
          this.paginator.pageIndex = 0;
          this.loadDataPage();
        }
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.table.dataSource = this.dataSource;

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const firstLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    const firstLoad$: Observable<boolean> = firstLoad.asObservable();

    let mergeEvent = merge(firstLoad$, this.sort.sortChange);

    if (this.paginator) {
      // @ts-ignore
      mergeEvent = merge(
        mergeEvent,
        this.paginator.page.pipe(
          tap((pageEvent: PageEvent) => {
            if (this.paginatorBottom) {
              this.paginatorBottom.pageSize = pageEvent.pageSize;
              this.paginatorBottom.pageIndex = pageEvent.pageIndex;
            }
          })
        )
      );
    }

    if (this.paginatorBottom) {
      // @ts-ignore
      mergeEvent = merge(
        mergeEvent,
        this.paginatorBottom.page.pipe(
          tap((pageEvent: PageEvent) => {
            this.paginator.pageSize = pageEvent.pageSize;
            this.paginator.pageIndex = pageEvent.pageIndex;
          })
        )
      );
    }

    mergeEvent.pipe(debounceTime(300)).subscribe(() => {
      this.loadDataPage(this.searchField);
    });
  }

  // this should be overwritten if needed to filter api calls
  get searchField(): SearchField {
    const filter = {};
    if (!!this.searchFilters) {
      for (const [key, value] of Object.entries(this.searchFilters)) {
        filter[key] = value;
      }
    }

    return filter;
  }

  get orderField() {
    return {};
  }

  initTable() {
    this.dataSource = new CustomDataSource(
      this.apiService,
      this.searchFunction,
      this.searchField,
      this.orderField,
      this.providedSource
    );
  }

  loadDataPage(filters: SearchField = {}, source = null) {
    this.pageLoaded.emit(true);
    this.dataSource.loadData(
      {
        ...this.searchField,
        ...this.orderField,
        ...filters,
      },
      source
    );
  }

  selectItem(selected_item_param: T) {
    this.selected_item = selected_item_param;
    this.item_selected.emit(this.selected_item);
  }
}
