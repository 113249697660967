import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Machine } from '../../models/machine.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IssueService } from '../../services/data/issue.service';
import { Issue } from '../../models/issue.model';
import { ProfileService } from '../../services/auth/profile.service';
import { manageFormError } from '../../helpers/FormErrors';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-issue-dialog',
  templateUrl: './issue-dialog.component.html',
  styleUrls: ['./issue-dialog.component.scss'],
})
export class IssueDialogComponent implements OnInit {
  issue_form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uptaster: Machine; issue_data: Issue },
    public dialogRef: MatDialogRef<IssueDialogComponent>,
    private fb: FormBuilder,
    private issueService: IssueService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.initIssueForm();
  }

  get dialogTitle() {
    if (this.data.issue_data) {
      return 'Modify Issue';
    }

    return 'Create Issue';
  }

  initIssueForm() {
    this.issue_form = this.fb.group({
      content: [null, Validators.required],
      start: [null, Validators.required],
      end: [null],
    });

    if (this.data.issue_data) {
      this.issue_form.patchValue(this.data.issue_data);
    }
  }

  async submit() {
    try {
      const issue_data = {
        uptaster: this.data.uptaster.url,
        issue_type: 'unavailability',
        start: this.issue_form.value.start,
        end: this.issue_form.value.end,
        content: this.issue_form.value.content,
      };

      if (this.data.issue_data) {
        await this.issueService.update(this.data.issue_data.url, new Issue(issue_data));
        this.dialogRef.close(true);
        this.snackbar.open('Issue modified', 'OK', {
          duration: 2000,
        });
        return;
      }

      await this.issueService.create(issue_data);
      this.dialogRef.close(true);
      this.snackbar.open('Issue created', 'OK', {
        duration: 2000,
      });
    } catch (e) {
      manageFormError(this.issue_form, e);
      console.error(e);
    }
  }
}
