import { Deserializable } from './deserializable';
import { Model } from './generic-model';
import { Machine } from './machine.model';
import { Location } from './location.model';
import { ExploitationStatus } from './exploitation-status.model';

export interface IInstallation {
  id?: number;
  url?: string;
  description?: string;
  end_date?: any;

  uptaster: Machine;
  location: Location;
  current_status: ExploitationStatus;
  start_date: any;
  is_exploited: boolean;
}

export class Installation extends Model implements Deserializable {
  description?: string;
  end_date?: any;

  uptaster: Machine;
  location: Location;
  start_date: any;
  is_exploited: boolean;
  current_status: ExploitationStatus;

  deserialize(input: IInstallation) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data?.uptaster === 'object' && data?.uptaster) {
      data.uptaster = new Machine(data.uptaster);
    }

    if (typeof data?.location === 'object' && data?.location) {
      data.location = new Location(data.location);
    }

    if (typeof data?.current_status === 'object' && data?.current_status) {
      data.current_status = new ExploitationStatus(data.current_status);
    }

    super(data);
  }

  get startDate() {
    if (!this.start_date) {
      return '-';
    }
    return this.formatTimeStampDate(this.start_date, 'ccc, DD, t');
  }

  get endDate() {
    if (!this.end_date) {
      return '-';
    }

    return this.formatTimeStampDate(this.end_date, 'ccc, DD, t');
  }

  get isExploited() {
    if (!this.is_exploited) {
      return 'No';
    }
    return 'Yes';
  }

  get descriptionMessage() {
    return this.description ? this.description : '-';
  }

  get currentStatus() {
    return this.current_status ? this.current_status.content : '-';
  }
}
