import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard/analytics',
    },

    // separator
    {
      name: 'Administration',
      type: 'separator',
    },
    {
      name: 'Campaigns',
      type: 'link',
      tooltip: 'Campaigns',
      icon: 'calendar_month',
      state: 'campaign',
    },
    {
      name: 'Machines',
      type: 'dropDown',
      tooltip: 'Machines',
      icon: 'laptop_windows',
      state: 'machines',
      sub: [
        { name: 'List', state: 'list' },
        { name: 'Stock images', state: 'stock-images' },
      ],
    },
    {
      name: 'Market Analysis',
      type: 'link',
      tooltip: 'Market Analysis',
      icon: 'description',
      state: 'market-analysis',
    },
    // {
    //   name: 'Users',
    //   type: 'link',
    //   tooltip: 'Users',
    //   icon: 'supervisor_account',
    //   state: 'users',
    // },
    {
      name: 'Locations',
      type: 'link',
      tooltip: 'Locations',
      icon: 'place',
      state: 'location',
    },
    {
      name: 'Products',
      type: 'link',
      tooltip: 'Products',
      icon: 'sell',
      state: 'product',
    },
    // {
    //   name: 'Hardware versions',
    //   type: 'link',
    //   tooltip: 'Hardware versions',
    //   icon: 'memory',
    //   state: 'hardware-version',
    // },
    {
      name: 'Packaging',
      type: 'link',
      tooltip: 'Packaging',
      icon: 'inventory',
      state: 'packaging',
    },
    {
      name: 'Company',
      type: 'dropDown',
      tooltip: 'Company',
      icon: 'apartment',
      state: 'company',
      sub: [
        { name: 'All', state: 'all' },
        { name: 'Banner', state: 'banner' },
        { name: 'Brand', state: 'brand' },
      ],
    },
    {
      name: 'Contact',
      type: 'dropDown',
      tooltip: 'Contact',
      icon: 'people',
      state: 'contact',
      sub: [
        { name: 'All', state: 'all' },
        { name: 'Banner Contact', state: 'banner-contact' },
      ],
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
