import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

export function manageFormError(
  form: FormGroup,
  errorResponse: HttpErrorResponse,
  ignoreNonExistingFieldError = false
) {
  Object.entries(errorResponse.error).forEach(([input, errors]) => {
    if (input === 'non_field_errors') {
      form.setErrors({ api: errors });
    } else {
      if (form.controls[input]) {
        form.controls[input].setErrors({ api: errors });
        form.controls[input].markAsTouched({ onlySelf: true });
      } else if (!ignoreNonExistingFieldError) {
        form.setErrors({ api: 'An error has occurred please contact administrator' });
      }
    }
  });
}
