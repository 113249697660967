import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NoteService } from '../../services/data/note.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Machine } from '../../models/machine.model';
import { ProfileService } from '../../services/auth/profile.service';
import { first } from 'rxjs/operators';
import { Note } from '../../models/note.model';
import { manageFormError } from '../../helpers/FormErrors';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss'],
})
export class NoteDialogComponent implements OnInit {
  note_form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uptaster: Machine; note_data: Note },
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    private fb: FormBuilder,
    private noteService: NoteService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.initNoteForm();
  }

  get dialogTitle() {
    if (this.data.note_data) {
      return 'Modify Note';
    }

    return 'Create Note';
  }

  initNoteForm() {
    this.note_form = this.fb.group({
      content: [null, Validators.required],
    });

    if (this.data.note_data) {
      this.note_form.patchValue(this.data.note_data);
    }
  }

  async submit() {
    try {
      const note_data = {
        uptaster: this.data.uptaster.url,
        content: this.note_form.value.content,
      };

      if (this.data.note_data) {
        await this.noteService.update(this.data.note_data.url, new Note(note_data));
        this.dialogRef.close(true);
        this.snackbar.open('Note modified', 'OK', {
          duration: 2000,
        });
        return;
      }

      await this.noteService.create({
        uptaster: this.data.uptaster.url,
        content: this.note_form.value.content,
      });

      this.dialogRef.close(true);
      this.snackbar.open('Note created', 'OK', {
        duration: 2000,
      });
    } catch (e) {
      manageFormError(this.note_form, e);
      console.error(e);
    }
  }
}
