<div class="input-quantity" [ngClass]="{ 'input-quantity--compact': compact }">
  <button
    *ngIf="!disabled"
    icon-button
    (click)="decrease()"
    color="accent"
    [ngClass]="
      compact ? 'input-quantity--compact__decrease input-quantity--compact__input' : 'input-quantity__quantity-button'
    "
  >
    <mat-icon class="input-quantity__quantity-button__minus">remove</mat-icon>
  </button>
  <div
    class="input-quantity__value"
    [class.input-quantity__value--disabled]="disabled"
    [ngClass]="{ 'input-quantity--compact__value': compact }"
  >
    <input
      type="number"
      [disabled]="false"
      [(ngModel)]="value"
      min="0"
      [max]="maxQuantity"
      [ngClass]="{ 'input-quantity--compact__value__input': compact }"
    />
  </div>
  <button
    *ngIf="!disabled"
    icon-button
    (click)="increase()"
    color="accent"
    [ngClass]="
      compact ? 'input-quantity--compact__increase input-quantity--compact__input' : 'input-quantity__quantity-button'
    "
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
