import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Machine } from '../../models/machine.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { manageFormError } from '../../helpers/FormErrors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InstallationService } from '../../services/data/installation.service';
import { Installation } from '../../models/installation.model';
import { SelectChoice } from '../../models/generic-model';
import { LocationService } from '../../services/data/location.service';
import { SearchField } from '../../models/api';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-installation-dialog',
  templateUrl: './installation-dialog.component.html',
  styleUrls: ['./installation-dialog.component.scss'],
})
export class InstallationDialogComponent implements OnInit {
  installation_form: FormGroup;
  form_mode: 'edit' | 'create';
  location_options: SelectChoice[] = [];
  non_field_errors: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uptaster: Machine; installation_data: Installation },
    public dialogRef: MatDialogRef<InstallationDialogComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private installationService: InstallationService,
    private locationService: LocationService
  ) {}

  async ngOnInit() {
    this.setFormMode();
    await this.initInstallationForm();
  }

  setFormMode() {
    if (!this.data?.installation_data) {
      this.form_mode = 'create';
      return;
    }

    this.form_mode = 'edit';
  }

  get dialogTitle() {
    if (this.data.installation_data) {
      return 'Modify Installation';
    }

    return 'Create Installation';
  }

  async initInstallationForm() {
    this.installation_form = this.fb.group({
      start_date: [null, Validators.required],
      location: [null, Validators.required],
      description: [null],
      end_date: [null],
    });

    await this.refreshLocationOptions();

    if (this.data?.installation_data) {
      this.installation_form.patchValue(this.data.installation_data);
    }

    if (this.form_mode === 'edit') {
      this.installation_form.patchValue(this.data?.installation_data);
      this.installation_form.get('location').patchValue(this.data.installation_data.location.url);
    }
  }

  async refreshLocationOptions(search = null) {
    const search_field: SearchField = {
      search: search ? search : '',
      limit: 20,
    };

    this.location_options = await this.locationService
      .list(search_field)
      .pipe(
        first(),
        map((location_response) => {
          if (!location_response?.results?.length) {
            return [];
          }

          let options = location_response.results.map((location_data) => {
            return {
              label: location_data.nameAddress,
              value: location_data.url,
            } as SelectChoice;
          });

          if (this.form_mode === 'edit') {
            const selected_option_exist = options.find(
              (item) => item.value === this.data.installation_data.location.url
            );
            if (!selected_option_exist) {
              options = [
                ...options,
                {
                  label: this.data.installation_data.location.name,
                  value: this.data.installation_data.location.url,
                },
              ];
            }
          }
          return options;
        })
      )
      .toPromise();
  }

  async submit() {
    try {
      const installation_data = {
        uptaster: this.data.uptaster.url,
        location: this.installation_form.value.location,
        description: this.installation_form.value.description,
        start_date: this.installation_form.value.start_date,
        end_date: this.installation_form.value.end_date,
      };

      if (this.form_mode === 'create') {
        await this.installationService.create(installation_data);
        this.snackbar.open('Installation modified', 'OK', {
          duration: 2000,
        });
        this.dialogRef.close(true);
      }

      if (this.form_mode === 'edit') {
        await this.installationService.update(this.data.installation_data.url, new Installation(installation_data));
        this.snackbar.open('Installation modified', 'OK', {
          duration: 2000,
        });
        this.dialogRef.close(true);
      }
    } catch (e) {
      if (e?.error?.non_field_errors?.length) {
        this.non_field_errors = e?.error?.non_field_errors;
      }

      manageFormError(this.installation_form, e);
    }
  }
}
