import { Deserializable } from './deserializable';
import { Model } from './generic-model';
export interface ICountry {
  id?: string;
  url?: string;
  created_at?: string;
  updated_at?: string;
  name: string;
}

export class Country extends Model implements Deserializable {
  created_at?: string;
  updated_at?: string;
  name: string;

  deserialize(input: ICountry) {
    Object.assign(this, input);
    return this;
  }
}
