import { Deserializable } from './deserializable';
import { Model } from './generic-model';
export interface IExploitationStatus {
  id?: number;
  url?: string;
  content?: string;
  is_exploited?: boolean;
  start_date: any;
  end_date?: any;
}

export class ExploitationStatus extends Model implements Deserializable {
  id?: any;
  url?: string;
  content?: string;
  is_exploited?: boolean;
  start_date: any;
  end_date?: any;

  deserialize(input: IExploitationStatus) {
    Object.assign(this, input);
    return this;
  }

  get startDate() {
    if (!this.start_date) {
      return '-';
    }
    return this.formatTimeStampDate(this.start_date, 'ccc, DD, t');
  }

  get endDate() {
    if (!this.end_date) {
      return '-';
    }

    return this.formatTimeStampDate(this.end_date, 'ccc, DD, t');
  }

  get dateRange() {
    if (!this.end_date) {
      return this.formatTimeStampDate(this.start_date, 'ccc, DD, t');
    }

    return `${this.formatTimeStampDate(this.start_date, 'ccc, DD, t')} - ${this.formatTimeStampDate(
      this.end_date,
      'ccc, DD, t'
    )}`;
  }
}
