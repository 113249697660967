import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-quantity',
  templateUrl: './input-quantity.component.html',
  styleUrls: ['./input-quantity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputQuantityComponent),
      multi: true,
    },
  ],
})
export class InputQuantityComponent implements OnInit, ControlValueAccessor {
  _value = 1;
  set value(str: number) {
    this._value = str;
    this.onChange(this._value);
  }
  get value() {
    return this._value;
  }

  @Input()
  disabled: boolean;

  _maxQuantity: number | boolean;

  @Input()
  set maxQuantity(max: number | boolean) {
    this._maxQuantity = max;
  }
  get maxQuantity() {
    if (this._maxQuantity === 0) {
      return 0;
    } else {
      return this._maxQuantity || 100000;
    }
  }

  @Input() compact = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(str: number): void {
    this.value = str;
  }

  increase() {
    if (this.maxQuantity && this.value < this.maxQuantity) {
      if (this.maxQuantity === 0) {
      }
      this.value++;
    }
  }

  decrease() {
    if (this.value > 0) {
      this.value--;
    }
  }
}
