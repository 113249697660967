import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css'],
})
export class AppLoaderComponent implements OnInit {
  title;
  message;
  constructor() {}

  ngOnInit() {}
}
