<div class="issue-dialog" [formGroup]="issue_form" *ngIf="issue_form">
  <h2 class="issue-dialog__title">{{ dialogTitle }}</h2>
  <div class="issue-dialog__form-field">
    <mat-form-field class="issue-dialog__form-field__form" appearance="standard">
      <mat-label>Message</mat-label>
      <textarea matInput [formControlName]="'content'" rows="8"></textarea>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="issue-dialog__form-field">
    <mat-form-field class="issue-dialog__form-field__form">
      <mat-label>Start date</mat-label>
      <input matInput [ngxMatDatetimePicker]="startPicker" [formControlName]="'start'" />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #startPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="issue-dialog__form-field">
    <mat-form-field class="issue-dialog__form-field__form">
      <mat-label>End date</mat-label>
      <input matInput [ngxMatDatetimePicker]="endPicker" [formControlName]="'end'" />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #endPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="issue-dialog__actions">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="issue_form.invalid">Submit</button>
  </div>
</div>
