import {
  AfterViewInit,
  ComponentFactoryResolver,
  ContentChild,
  Directive,
  ElementRef,
  Host,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatError, MatFormField } from '@angular/material/form-field';
import { EMPTY, merge, Observable } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { FormSubmitDirective } from './form-submit.directive';

// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5

export const defaultErrors = {
  required: () => `This field is required`,
  message: (message: string) => `${message}`,
  email: () => `Email invalid`,
  min: (minError) => `Value (${minError.actual}) is smaller than allowed (${minError.min}) `,
  api: (errors: string[]) => errors[0],
  maxContentSize: (error, fileSizePipe) => {
    const actualSize = fileSizePipe.transform(error.actualSize);
    const maxSize = fileSizePipe.transform(error.maxSize);
    return `The file size is too large`;
  },
};
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
@Directive({
  selector: 'mat-form-field',
})
export class ControlErrorsDirective implements OnInit, AfterViewInit, OnDestroy {
  control: NgControl;
  @ContentChild(MatInput, { read: ElementRef })
  controlElementRef: ElementRef;
  @ContentChild(MatError, { read: ElementRef }) matError: ElementRef;
  submit$: Observable<Event>;
  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private formField: MatFormField,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(FORM_ERRORS) private errors,
    @Optional() @Host() private form: FormSubmitDirective
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }
  public ngAfterViewInit() {
    if (this.controlElementRef) {
      this.renderer.listen(this.controlElementRef.nativeElement, 'blur', () => this.onChange(null));
    }
    this.control = this.formField._control.ngControl;
    if (this.control) {
      merge(this.control.statusChanges, this.submit$)
        .pipe(untilDestroyed(this))
        .subscribe((data) => this.onChange(data));
    }
  }
  ngOnInit() {}
  onChange(data) {
    const controlErrors = this?.control?.errors;
    if (controlErrors && this?.control?.invalid) {
      const firstKey = Object.keys(controlErrors)[0];
      const getError = this.errors[firstKey]; // Set the value of the error

      this.setError(getError(controlErrors[firstKey]));
    } else {
      this.setError(null);
    }
  }
  setError(text: string) {
    /*
    I can't find how to add the component inside instead of after
    So for the moment, it's still required to add <mat-error></mat-error>
    to an input
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(
        ControlErrorComponent
      );
      this.ref = this.vcr.createComponent(factory);
    }*/
    if (this.matError) {
      this.matError.nativeElement.innerHTML = text;
    }
  }
  ngOnDestroy(): void {}
}
