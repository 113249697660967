import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { finalize, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseApi, SearchField } from '../models/api';

export class CustomDataSource<T> extends DataSource<T> {
  paginator: MatPaginator;
  sort: MatSort;

  dataSubject = new BehaviorSubject<T[]>(null);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  waitForApi = true;

  public noData$ = this.connect().pipe(
    map((data: T[]) => {
      return !!data && data.length === 0;
    })
  );

  public length = 0;

  public itemsOnThisPage = 0;

  public totals = null;

  constructor(
    private dataService,
    private searchFunction: any,
    private defaultSearchField?: SearchField,
    private defaultOrderField?: SearchField,
    private providedSource?: T[]
  ) {
    super();
  }

  loadData(pSearchField: SearchField = null, source: T[] = null) {
    if (source?.length) {
      this.providedSource = source;
    }

    this.waitForApi = true;

    this.loadingSubject.next(true);

    let searchFields: SearchField = {};

    if (this.paginator) {
      const offset: number = this.paginator.pageSize * this.paginator.pageIndex;
      const limit: number = this.paginator.pageSize;

      searchFields = {
        limit: limit.toString(),
        offset: offset.toString(),
      };
    }
    if (pSearchField) {
      searchFields = { ...searchFields, ...pSearchField };
    }

    if (this.defaultSearchField) {
      searchFields = { ...searchFields, ...this.defaultOrderField };
    }

    if (this.defaultOrderField) {
      searchFields = { ...searchFields, ...this.defaultOrderField };
    }

    const ordering = this.getSortingParam();
    if (ordering) {
      searchFields.ordering = ordering;
    }

    if (this?.providedSource !== null) {
      this.dataSubject.next(this.providedSource);

      this.length = this.providedSource.length;
      this.loadingSubject.next(false);
      this.waitForApi = false;
      return;
    }

    this.dataService[this.searchFunction](searchFields)
      .pipe(
        finalize(() => this.loadingSubject.next(false)),
        tap((responseApi: ResponseApi<T>) => {
          this.length = responseApi.count;
          this.totals = responseApi.totals;
          this.itemsOnThisPage = responseApi.results?.length;
        }),
        map((responseApi: ResponseApi<T>) => responseApi.results)
      )
      .subscribe((data: T[]) => {
        this.waitForApi = false;
        this.dataSubject.next(data);
      });
  }

  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  getSortingParam() {
    if (this.sort?.active) {
      let order = '';
      if (this.sort.direction === 'desc') {
        order = '-';
      }
      return `${order}${this.sort.active}`;
    }
    return null;
  }
}
