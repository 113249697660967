<div class="file-upload-component">
  <div
    class="file-upload-component__input-container"
    [ngClass]="{ 'file-upload-component__input-container--row': style === 'row' }"
    [class.disabled]="isUploadDisabled"
    [matTooltip]="isUploadDisabled ? 'File(s) already uploaded' : null"
    [matTooltipPosition]="'above'"
    appDnd
    (fileDropped)="onFileDropped($event)"
  >
    <input
      type="file"
      class="file-upload-component__input-container__input-field"
      [multiple]="isMultipleFiles"
      [disabled]="isUploadDisabled"
      id="input-{{ uuid }}"
      [accept]="fileTypes"
      (change)="fileBrowseHandler($event)"
    />

    <mat-icon class="file-upload-component__input-container__icon">drive_folder_upload</mat-icon>
    <span>Drag and drop here</span>
    <span>or</span>
    <span style="color: #7367f0; font-weight: bold">Open file browser</span>
  </div>

  <span class="file-upload-component__error" *ngIf="error">
    {{ error }}
  </span>

  <div
    class="file-upload-component__file-viewer"
    cdkDropList
    (cdkDropListDropped)="reordering($event)"
    [cdkDropListDisabled]="!reorderList"
  >
    <ng-container *ngIf="refreshing_current_files">
      <div class="file-upload-component__file-viewer__item">
        <div class="file-upload-component__file-viewer__item__info">
          <mat-spinner diameter="30" strokeWidth="5"></mat-spinner>
          <span class="file-upload-component__file-viewer__item__info__name"> Loading files.. </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngFor="let file of uploadedFiles; let index = index">
      <div
        class="file-upload-component__file-viewer__item"
        cdkDrag
        [matTooltip]="file.file_url.startsWith('data') ? 'File pending' : null"
        [matTooltipPosition]="'above'"
        [ngClass]="{ 'file-upload-component__file-viewer__item--pending': file.file_url.startsWith('data') }"
      >
        <div class="file-upload-component__file-viewer__item__info">
          <mat-icon
            *ngIf="reorderList"
            style="cursor: move"
            [matTooltip]="'Reorder list'"
            [matTooltipPosition]="'above'"
            >import_export</mat-icon
          >

          <div class="file-upload-component__file-viewer__item__info__download">
            <ng-container *ngIf="fileType === 'image' && file?.file_url; else documentFile">
              <ng-container *ngIf="!file.file_url.startsWith('data'); else imageNotAvailable">
                <div
                  class="file-upload-component__file-viewer__item__info__download__hover"
                  [matTooltip]="'Download file'"
                  [matTooltipPosition]="'above'"
                  (click)="downloadFile(file)"
                >
                  <mat-icon>file_download</mat-icon>
                </div>
                <img class="file-upload-component__file-viewer__item__info__image" [src]="file?.file_url" />
              </ng-container>
              <ng-template #imageNotAvailable>
                <img class="file-upload-component__file-viewer__item__info__image" [src]="file?.file_url" />
              </ng-template>
            </ng-container>
            <ng-template #documentFile>
              <ng-container *ngIf="!file.file_url.startsWith('data'); else downloadNotAvailable">
                <div
                  class="file-upload-component__file-viewer__item__info__download__hover"
                  [matTooltip]="'Download file'"
                  [matTooltipPosition]="'above'"
                  (click)="downloadFile(file)"
                >
                  <mat-icon>file_download</mat-icon>
                </div>
                <mat-icon class="file-upload-component__file-viewer__item__info__document">description</mat-icon>
              </ng-container>
              <ng-template #downloadNotAvailable>
                <mat-icon class="file-upload-component__file-viewer__item__info__document">description</mat-icon>
              </ng-template>
            </ng-template>
          </div>

          <span class="file-upload-component__file-viewer__item__info__name">{{ !!file?.name ? file.name : '-' }}</span>
        </div>
        <div class="file-upload-component__file-viewer__item__remove">
          <mat-icon (click)="deleteFile(index)">cancel</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
