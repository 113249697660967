import { Deserializable } from './deserializable';
import { User } from './user.model';
import { Model } from './generic-model';

export interface INote {
  created_at?: string;
  id?: number;
  updated_at?: string;
  url?: string;
  author?: User;
  content?: string;
  uptaster?: string;
}

export class Note extends Model implements Deserializable {
  created_at?: string;
  updated_at?: string;
  content?: string;
  uptaster?: string;
  author?: User;

  deserialize(input: INote) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data.author === 'object' && data.author) {
      data.author = new User(data.author);
    }
    super(data);
  }

  get creationDate() {
    return this.formatTimeStampDate(this.created_at, 'DDDD t');
  }
}
