import { DateTime } from 'luxon';

export interface SelectChoice {
  label?: string;
  value: string;
}

export class Model {
  id?: string;
  url?: string;

  constructor(data: any = {}) {
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        this[name] = data[name];
      }
    }
  }

  convertToJSDate?(date) {
    const isValidDate = date instanceof Date;

    if (isValidDate) {
      return date;
    }

    return new Date(date);
  }

  formatTimeStampDate?(timeStamp, format: string) {
    const isValidDate = timeStamp instanceof Date;
    const date = isValidDate ? timeStamp : this.convertToJSDate(timeStamp);

    return DateTime.fromJSDate(date).setLocale('en').toFormat(format);
  }
}
