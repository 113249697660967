import { Deserializable } from './deserializable';
import { Model } from './generic-model';

export interface StockImages {
  name: string;
  url: string;
}

export interface IMachine {
  created_at?: string;
  id?: number;
  updated_at?: string;
  url?: string;
  ams_id?: string;
  lte_sim_id?: string;
  name?: string;
  note?: string;
  location?: string;
  banner?: string;
  locations?: any[];
  unavailable?: any[];
}

export class Machine extends Model implements Deserializable {
  created_at?: string;
  id?: any;
  updated_at?: string;
  url?: string;
  ams_id?: string;
  lte_sim_id?: string;
  name?: string;
  note?: string;
  location?: string;
  banner?: string;
  locations?: any[];
  unavailable?: any[];

  deserialize(input: IMachine) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (data?.stock) {
      data.stock = `data:image/jpg;base64,${data.stock}`;
    }

    super(data);
  }

  get machineName() {
    if (this.banner) {
      return `${this.name} - ${this.location} - ${this.banner}`;
    }
    return `${this.name} - ${this.location}`;
  }
}
