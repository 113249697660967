import { Deserializable } from './deserializable';
import { Model } from './generic-model';
import { Banner } from './banner.model';
import { Contact } from './contact.model';
import { Region } from './region.model';

export interface ILocation {
  id?: string;
  url?: string;
  alias?: string;
  banner_code?: string;
  street_number?: string;
  street?: string;
  address?: string;
  banner?: Banner;
  contact?: Contact;
  created_at?: string;
  updated_at?: string;

  name: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
}

export class Location extends Model implements Deserializable {
  alias?: string;
  banner_code?: string;
  street_number?: string;
  street?: string;
  address?: string;
  banner?: Banner;
  contact?: Contact;
  created_at?: string;
  updated_at?: string;

  name: string;
  postal_code: string;
  city: string;
  region: Region;

  deserialize(input: ILocation) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data?.banner === 'object' && !!data?.banner) {
      data.banner = new Banner(data.banner);
    }

    if (typeof data?.contact === 'object' && data?.contact) {
      data.contact = new Contact(data.contact);
    }

    if (typeof data?.region === 'object' && data?.region) {
      data.contact = new Region(data.contact);
    }

    super(data);
  }

  get aliasInfo() {
    return this.alias ? this.alias : '-';
  }

  get bannerCode() {
    if (!this?.banner?.name || !this.banner_code) {
      return '-';
    }

    return `${this.banner.name}#${this.banner_code}`;
  }

  get fullAddress(): string {
    if (this.address) {
      return this.address;
    }

    const street = () => {
      if (!this.street_number && !this.street) {
        return '';
      }

      const number = this.street_number ? this.street_number : '';
      const street_name = this.street ? ` ${this.street}` : '';

      return `${number}${street_name}, `;
    };

    return `${street()}${this.postal_code} ${this.city}, ${this.region?.name}, ${this.region?.country?.name}`;
  }

  get nameAddress() {
    return `${this.name} (${this.fullAddress})`;
  }
}
