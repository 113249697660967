import { Deserializable } from './deserializable';
import { Model } from './generic-model';
export interface ICompany {
  id?: string;
  url?: string;
  logo?: string;
  crm?: string;
  created_at?: string;
  updated_at?: string;

  name: string;
}

export class Company extends Model implements Deserializable {
  logo?: string;
  crm?: string;
  created_at?: string;
  updated_at?: string;

  name: string;

  deserialize(input: ICompany) {
    Object.assign(this, input);
    return this;
  }

  get crmLink() {
    return this.crm ? this.crm : '-';
  }
}
