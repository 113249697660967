import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedDirectivesModule } from '../directives/shared-directives.module';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './header-side/header-side.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';

// ALWAYS REQUIRED
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from '../services/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';

import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { BottomSheetConfirmationComponent } from './bottom-sheet-confirmation/bottom-sheet-confirmation.component';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import { InputQuantityComponent } from './input-quantity/input-quantity.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NoteDialogComponent } from './note-dialog/note-dialog.component';
import { IssueDialogComponent } from './issue-dialog/issue-dialog.component';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InstallationDialogComponent } from './installation-dialog/installation-dialog.component';
import { ExploitationStatusDialogComponent } from './exploitation-status-dialog/exploitation-status-dialog.component';

const components = [
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  ButtonLoadingComponent,
  FooterComponent,
  CustomTableComponent,
  BottomSheetConfirmationComponent,
  DragAndDropComponent,
  InputQuantityComponent,
  NoteDialogComponent,
  IssueDialogComponent,
  AutoCompleteComponent,
  InstallationDialogComponent,
  ExploitationStatusDialogComponent,
];

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'DD MMM YYYY, LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    NgxMatSelectSearchModule,
  ],
  declarations: components,
  entryComponents: [AppComfirmComponent, AppLoaderComponent],
  exports: components,
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-ca' },
  ],
})
export class SharedComponentsModule {}
