import { Deserializable } from './deserializable';
import { Model } from './generic-model';
import { User } from './user.model';

export interface IIssue {
  id?: number;
  url?: string;
  author?: User;
  content?: string;
  issue_type?: 'unavailability' | '';
  start?: any;
  end?: any;
  solved?: boolean;
  created_at?: string;
  updated_at?: string;
}

export class Issue extends Model implements Deserializable {
  url?: string;
  author?: User;
  content?: string;
  issue_type?: 'unavailability' | '';
  start?: any;
  end?: any;
  solved?: boolean;
  created_at?: string;
  updated_at?: string;

  deserialize(input: IIssue) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data.author === 'object' && data.author) {
      data.author = new User(data.author);
    }
    super(data);
  }

  get creationDate() {
    if (!this.created_at) {
      return '-';
    }

    return this.formatTimeStampDate(this.created_at, 'ccc, DD, t');
  }

  get startDate() {
    if (!this.start) {
      return '-';
    }
    return this.formatTimeStampDate(this.start, 'ccc, DD, t');
  }

  get endDate() {
    if (!this.end) {
      return '-';
    }

    return this.formatTimeStampDate(this.end, 'ccc, DD, t');
  }

  get endDateETA() {
    if (!this.end) {
      return '-';
    }

    let eta = '';
    if (!this.solved) {
      eta = '[ETA] ';
    }

    return `${eta}${this.formatTimeStampDate(this.end, 'ccc, DD, t')}`;
  }
}
