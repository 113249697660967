import { Injectable } from '@angular/core';
import GlobalService from '../globalService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseApi, SearchField } from '../../models/api';
import { Observable } from 'rxjs';
import { INote, Note } from '../../models/note.model';
import { first, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NoteService extends GlobalService {
  url_note = `${this.api_url}/notes/`;

  constructor(public http: HttpClient) {
    super();
  }

  list(searchFields: SearchField): Observable<ResponseApi<Note>> {
    const headers = this.getHeaders();

    const params: HttpParams = new HttpParams({
      fromObject: searchFields,
    });

    return this.http.get<ResponseApi<INote>>(this.url_note, { headers, params }).pipe(
      map((responseApi: ResponseApi<INote>) => {
        return {
          ...responseApi,
          results: responseApi.results.map((data) => {
            return new Note(data);
          }),
        };
      })
    );
  }

  create(note): Promise<INote> {
    const headers = this.getHeaders();
    return this.http.post<any>(this.url_note, note, { headers: headers }).pipe(first()).toPromise();
  }

  update(url: string, note: Note): Promise<any> {
    const headers = this.getHeaders();
    return this.http.patch<any>(url, note, { headers: headers }).pipe(first()).toPromise();
  }

  get(id): Observable<Note> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.url_note + id, { headers: headers }).pipe(
      map((note_data) => {
        return new Note(note_data);
      })
    );
  }
}
