import { Deserializable } from './deserializable';
import { Model } from './generic-model';
import { Company } from './company.model';
export interface IContact {
  id?: number;
  url?: string;
  crm?: string;
  email?: string;
  phone?: string;
  created_at?: string;
  updated_at?: string;

  name: string;
  company: Company;
  preferred_language: 'french' | 'english';
}

export class Contact extends Model implements Deserializable {
  crm?: string;
  email?: string;
  phone?: string;
  created_at?: string;
  updated_at?: string;

  name: string;
  company: Company;
  preferred_language: 'french' | 'english';

  deserialize(input: IContact) {
    Object.assign(this, input);
    return this;
  }

  constructor(data: any = {}) {
    if (typeof data?.company === 'object' && data?.company) {
      data.company = new Company(data.company);
    }

    super(data);
  }

  get contactEmail() {
    return this.email ? this.email : '-';
  }

  get contactPhone() {
    return this.phone ? this.phone : '-';
  }
}
