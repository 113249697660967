<div class="exploitation_status-dialog" [formGroup]="exploitation_status_form" *ngIf="exploitation_status_form">
  <h2 class="exploitation_status-dialog__title">{{ dialogTitle }}</h2>

  <div class="exploitation_status-dialog__form-field exploitation_status-dialog__form-field--multi">
    <mat-form-field class="exploitation_status-dialog__form-field__form">
      <mat-label>Start date</mat-label>
      <input matInput [ngxMatDatetimePicker]="startPicker" [formControlName]="'start_date'" />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #startPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field class="exploitation_status-dialog__form-field__form">
      <mat-label>End date</mat-label>
      <input matInput [ngxMatDatetimePicker]="endPicker" [formControlName]="'end_date'" />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #endPicker
        [showSpinners]="true"
        [touchUi]="true"
        [enableMeridian]="true"
      ></ngx-mat-datetime-picker>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="exploitation_status-dialog__form-field">
    <mat-form-field class="exploitation_status-dialog__form-field__form" appearance="standard">
      <mat-label>Status</mat-label>
      <textarea matInput [formControlName]="'content'" rows="8"></textarea>
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="exploitation_status-dialog__actions">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="exploitation_status_form.invalid">
      Submit
    </button>
  </div>

  <ng-container *ngIf="non_field_errors.length">
    <div class="exploitation_status-dialog__non_field_errors" *ngFor="let error of non_field_errors">
      <span>{{ error }}</span>
    </div>
  </ng-container>
</div>
