import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [
    {
      name: 'EN',
      code: 'en',
      flag: 'flag-icon-us',
    },
    {
      name: 'ES',
      code: 'es',
      flag: 'flag-icon-es',
    },
  ];
  currentLang = this.availableLangs[0];

  public matxThemes;
  public layoutConf: any;
  constructor(private themeService: ThemeService, private layout: LayoutService, public auth: AuthenticationService) {}

  ngOnInit() {
    this.matxThemes = this.themeService.matxThemes;
    this.layoutConf = this.layout.layoutConf;
  }

  setLang(lng) {}

  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
          sidebarCompactToggle: false,
        },
        {
          transitionClass: true,
        }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
        sidebarCompactToggle: true,
      },
      {
        transitionClass: true,
      }
    );
  }

  onSearch(e) {
    //   console.log(e)
  }
}
