import { Deserializable } from './deserializable';
import { Model } from './generic-model';

export interface IBanner {
  id?: string;
  url?: string;
  logo?: string;
  crm?: string;
  created_at?: string;
  updated_at?: string;

  name: string;
}

export class Banner extends Model implements Deserializable {
  logo?: string;
  crm?: string;
  created_at?: string;
  updated_at?: string;

  name: string;

  deserialize(input: IBanner) {
    Object.assign(this, input);
    return this;
  }

  get crmLink() {
    return this.crm ? this.crm : '-';
  }

  get companyUrl() {
    if (!this.url) {
      return null;
    }

    return this.url.replace('banners', 'companies');
  }
}
